import React from 'react';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

export default () => (
  <ArticleLayout category="about" title="宗旨" subtitle="我们坚持的行动原则">
    <KeyPoint
      index="○"
      title="战略设计"
      content="我们信奉长期主义，坚持正确而困难的事情。在构建软件之前，我们在顶层设计上充分思考，力求高屋建瓴、明察秋毫，更高效地输出价值。"
    />
    <KeyPoint
      index="○"
      title="技术驱动"
      content="我们致力于解决技术方面的难题，将策略与实践结合起来。我们了解正在影响未来发展的趋势，投资于打造体验和交付实力，迎接一切挑战。"
    />
    <KeyPoint
      index="○"
      title="敏捷开发"
      content="面临复杂且不断变化的需求，我们秉承业务优先的原则，精益求精、快速迭代，加强客户的技术核心力量，保障灵活扩展，打造流畅的数字体验。"
    />
    <KeyPoint
      index="○"
      title="共同探索"
      content="我们重视长期合作，与合作伙伴一起成长。我们并不盲目地应用新潮的技术，我们为不同规模的客户建立合理的技术发展路线。"
    />
  </ArticleLayout>
);
